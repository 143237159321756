<script setup lang="ts">
import { LocalStorageKey } from '@/modules/core/enums'
import { EventName } from '@/modules/trevo/types/enum'
import type { ISearchProcedureResponseItem } from '@/modules/search/types/api'

defineProps({
  compact: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['startProcedureOCR', 'finishedProcedureOCR', 'canceledProcedureOCR'])
const showProcedureOCR = ref(false)
const currentStep = ref(0)
const fileUploadError = ref(false)
const fileInput = ref<File | null>(null)
const ocrResult = ref<any>(null)
const prescriptionHashes = useLocalStorage<string[]>(LocalStorageKey.PRESCRIPTION_HASHES, [])

const { addSlug, removeSlug, procedures, submitOCR, getPrescriptionIds } = useProcedures()
const { customer } = useCustomer()

const initialSelected = computed(() => {
  if (!procedures.value.length || procedures.value.length === 1)
    return null as never

  return procedures.value.map(({ procedure: { name, slug, id } }: any) => ({ name, slug, id }))
})

async function startProcedureOCR(): Promise<void> {
  emit('startProcedureOCR')
  currentStep.value = 1

  if (fileInput.value) {
    fileUploadError.value = false
    try {
      ocrResult.value = await submitOCR(fileInput.value)

      if (customer.value?.id && ocrResult.value?.file_hash) {
        getPrescriptionIds(customer.value.id, [ocrResult.value.file_hash])
      }
      else if (ocrResult.value?.file_hash) {
        prescriptionHashes.value.push(ocrResult.value.file_hash)
      }
    }
    catch {
      fileUploadError.value = true
    }
    finally {
      currentStep.value = 2
    }
  }
}

function finishProcedureOCR(): void {
  let path = `/${useLocationParam().value}/exames?exames=${useProcedureSlug().value?.join(',')}`
  emit('finishedProcedureOCR')
  if (!customer.value?.id) {
    path = `${path}&authRequired=true`
  }
  navigateTo(path)
  closeProcedureOCR()
}

function closeProcedureOCR(): void {
  emit('canceledProcedureOCR')
  currentStep.value = 0
  showProcedureOCR.value = false
  fileInput.value = null
}

function handleFileInput(file: any): void {
  fileInput.value = file
}

function procedureNameShow(name: string): string {
  return name.length > 45 ? `${name.slice(0, 45)}...` : name
}

function openSideSheet(): void {
  if (procedures.value?.length) {
    currentStep.value = 2
  }
  showProcedureOCR.value = true
}

function handleRemoveSlug(procedure: ISearchProcedureResponseItem) {
  track(EventName.ExamRemove, {
    procedureDisplayId: procedure.id,
    displayName: procedure.name,
  })

  removeSlug(procedure.slug)
}

watch(fileInput, (newFile) => {
  if (newFile) {
    startProcedureOCR()
  }
})
</script>

<template>
  <div>
    <div class="banner-ocr" :class="compact ? 'compact' : 'homepage'">
      <h3 class="title">
        Agora você pode fazer seu orçamento rapidamente usando <b>seu pedido médico</b>.
      </h3>
      <p v-if="!compact" class="subtitle">
        Experimente e veja como é simples e rápido obter seu orçamento!
      </p>
      <Button
        class="open"
        variant="white"
        @click="openSideSheet"
      >
        Enviar Pedido Médico
      </Button>
      <TIllustrationHomeBanner class="hidden xl:block absolute right-0 bottom-0" />
    </div>

    <SideSheet
      :show="showProcedureOCR"
      no-title
      @close-modal="closeProcedureOCR"
    >
      <template #content>
        <div class="px-4 h-full w-full">
          <transition name="fade" mode="out-in">
            <div v-if="currentStep === 0" class="modal-content">
              <div class="content-header">
                <h2 class="text-2xl font-bold mb-4">
                  Carregar e anexar arquivo
                </h2>
                <p class="text-lg mb-4">
                  Carregue e anexe arquivos de seu pedido médico
                </p>
                <Icon
                  name="mdi:close"
                  class="text-black absolute right-0 top-0 p-2 w-10 h-10 cursor-pointer"
                  @click="closeProcedureOCR"
                />
              </div>
              <div class="content-body">
                <CoreUploadFile type="file" class="mb-4" @change="handleFileInput" />
                <SearchProcedure
                  no-button
                  :initial-selected="initialSelected"
                  without-ordenation
                  @remove-procedure="handleRemoveSlug"
                  @select-procedure="addSlug"
                />
              </div>
            </div>
            <div v-else-if="currentStep === 1" class="modal-content justify-center items-center">
              <TIllustrationSearchingState />
            </div>
            <div v-else-if="currentStep === 2" class="modal-content">
              <div class="content-header">
                <h2 class="text-2xl font-bold mb-4">
                  Confirme os exames encontrados
                </h2>
                <p class="text-lg mb-4">
                  Adicione ou remova exames caso a lista encontrada não esteja correta.
                </p>
                <Icon
                  name="mdi:close"
                  class="text-black absolute right-0 top-0 p-2 w-10 h-10 cursor-pointer"
                  @click="closeProcedureOCR"
                />
              </div>
              <div class="content-body">
                <div v-if="fileUploadError" class="flex flex-col">
                  <Icon
                    name="mdi:magnify" class="bg-primary-99 text-primary-50 w-12 h-12 rounded-full p-2 mx-auto mb-8"
                  />
                  <p class="text-neutral-30 text-center mb-8">
                    Parece que o texto no arquivo pode estar um pouco difícil de ler ou a imagem talvez não tenha uma boa
                    resolução. Você pode tentar enviar um novo arquivo ou, se preferir, realizar a busca manualmente abaixo.
                  </p>
                </div>
                <CoreUploadFile type="file" class="mb-4" @change="handleFileInput" />
                <SearchProcedure
                  id="ocr-input"
                  no-button
                  no-badge
                  :initial-selected="initialSelected"
                  without-ordenation
                  @remove-procedure="handleRemoveSlug"
                  @select-procedure="addSlug"
                />
                <div v-if="procedures.length">
                  <span class="block text-base font-bold my-2">{{ procedures.length }} exames encontrados</span>
                  <ul class="selected-list">
                    <li v-for="({ procedure }, index) in procedures" :key="index" class="relative py-2">
                      {{ procedureNameShow(procedure.name) }}
                      <Icon
                        name="mdi:close"
                        class="text-black absolute right-0 top-2 p-0 w-6 h-6 cursor-pointer"
                        @click="handleRemoveSlug(procedure)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div class="content-footer">
                <Button
                  class="w-full h-[56px] text-white font-bold"
                  @click="finishProcedureOCR"
                >
                  Buscar orçamento
                </Button>
                <Button
                  class="w-full h-[56px] text-white font-bold"
                  variant="outlined"
                  @click="closeProcedureOCR"
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </transition>
        </div>
      </template>
    </SideSheet>
  </div>
</template>

<style scoped>
.modal-content{
  @apply h-full w-full flex flex-col;
}

.content-header {
  @apply relative;
}

.content-body {
  @apply h-full flex flex-col gap-4 justify-center overflow-y-auto;
}

.content-footer {
  @apply w-full flex gap-2;
}

.banner-ocr {
  @apply flex flex-col gap-4 bg-gradient-to-r from-[#FFA317] to-[#66C8D9] p-4 lg:px-10 lg:py-12
  sm:mx-auto mt-4 lg:mt-8 sm:w-full;
}

.banner-ocr.compact {
  @apply relative rounded-2xl md:mt-8 pt-12 pb-6 sm:pb-12;
}

.banner-ocr.homepage {
  @apply absolute sm:rounded-2xl w-[calc(100%+2rem)] md:w-full -mx-[1rem] md:mx-auto xl:-bottom-[160px] -mb-[8.25rem] -mt-[28px] md:mt-8 pt-12 pb-6 sm:pb-12 z-[-1] xl:z-[0];
}

.banner-ocr .title{
  @apply text-white text-xl lg:text-2xl font-normal;
}
.banner-ocr.homepage .title{
  @apply max-w-[660px];
}
.banner-ocr .subtitle{
  @apply text-white text-base lg:text-xl max-w-[660px];
}
.banner-ocr .open {
  @apply w-fit font-bold;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
