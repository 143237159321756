<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    default: '',
  },

  icon: {
    type: String,
    default: '',
  },

  notFound: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <div
    class="flex items-center bg-white border  w-fit py-1 px-1 rounded-md cursor-pointer"
    :class="notFound ? 'border-error-90' : 'border-neutral-60'"
  >
    <span
      class="font-medium text-xs whitespace-nowrap"
      :class="notFound ? 'text-error-90' : 'text-neutral-30 '"
    >
      {{ props.text }}
    </span>
    <Icon
      v-if="props.icon"
      :name="props.icon"
      class="ml-1"
      :class="notFound ? 'text-error-90' : 'text-neutral-30'"
      width="12"
      height="12"
    />
  </div>
</template>
