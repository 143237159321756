<script setup lang="ts">
const props = defineProps({
  accept: {
    type: Array<string>,
    default: () => ['image/jpg', 'image/png', 'image/jpeg', 'application/pdf'],
  },
})
const emit = defineEmits(['uploadChange', 'uploadError', 'change'])

interface IUpload {
  file: File
  message: string
  error: boolean
}

const acceptTypes = computed(() => props.accept.map(type => `.${type.split('/').pop()}`).join(','))

const uploads = ref<IUpload[]>([])
const inputFile = ref(null)

function fileIsOk(file: File) {
  if (!props.accept.includes(file.type)) {
    return {
      error: true,
      message: 'Formato inválido. Aceitamos arquivos em PDF, JPG, JPEG ou PNG.',
    }
  }
  // file > 10mb
  if (file.size > 10485760) {
    return {
      error: true,
      message: 'Tamanho do arquivo excedido. Aceitamos arquivos até 10MB.',
    }
  }
  return {
    error: false,
    message: '',
  }
}

async function handleFileUpload({ $event, isDrop }: any) {
  const files = isDrop ? $event.dataTransfer.files : $event.target.files

  for (const file of files) {
    const { error } = fileIsOk(file)

    if (!error) {
      emit('change', file)
    }
  }
}
</script>

<template>
  <div class="w-full">
    <div @dragover.prevent @dragleave.prevent @drop.prevent="handleFileUpload({ $event, isDrop: true })">
      <label
        for="file"
        :class="{
          'flex-row justify-start py-4 px-4 border-neutral-60': uploads.length,
          'flex-col p-8 border-black': !uploads.length,
        }"
        class="w-full cursor-pointer flex items-center border border-dashed border-neutral-60 text-neutral-40 rounded-lg"
      >
        <div
          class="flex items-center gap-4"
          :class="{ 'flex-col justify-center': !uploads.length }"
        >
          <div class="bg-neutral-variant-96 size-10 rounded-full flex items-center justify-center mb-3 xl:mb-0">
            <Icon
              name="mdi:tray-arrow-up"
              class="w-6 h-6 text-primary-50"
            />
          </div>

          <div class="flex flex-col">
            <span
              class="text-primary-50 text-center text-base font-bold leading-6"
              @dragover.prevent
              @dragleave.prevent
              @drop.prevent="handleFileUpload({ $event, isDrop: true })"
            >
              {{
                uploads.length
                  ? 'Enviar pedido médico'
                  : 'Clique para carregar pedido médico'
              }}
            </span>

            <p class="text-center leading-5 text-neutral-30">
              <template v-if="!uploads.length">
                <span
                  class="text-base leading-6"
                >
                  ou arraste e solte seus arquivos
                </span>

                <br>
              </template>

              <span class="leading-5 text-sm">
                JPG, PNG ou PDF (max. 10MB)
              </span>
            </p>
          </div>
        </div>

        <input
          id="file"
          ref="inputFile"
          type="file"
          name="file"
          class="opacity-0 absolute pointer-events-none"
          :accept="acceptTypes"
          @change="handleFileUpload({ $event, isDrop: false })"
        >
      </label>
    </div>
  </div>
</template>
